<template>
    <div class="AuditTrail full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-6"
                                mandatory
                                id="pathmodal-list"
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>

                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''"  @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>

            <div class="content-area pa-8 flex-grow-1 bg-white">
                <v-row class="d-flex align-center justify-space-between mb-5 filter">
                    <v-col cols="5" class="d-flex align-center justify-space-between pl-0 pr-0">
                        <p>{{ $t('general.search') }}:</p>
                        <textinput inputName="auditSearch" class="ml-6" @onInputFocus="onInputFocus"></textinput>
                    </v-col>
                    <v-col cols="6" class="d-flex align-center justify-space-between">
                        <p>{{ $t('general.from') }}:</p>
                        <v-dialog ref="datedialogfrom" v-model="datemodalfrom" :return-value.sync="searchDateFrom" persistent width="350px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchDateFromFormatted"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    class="ml-6 mr-6 is-modal"
                                    id="datemodalfrom"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="searchDateFrom"
                                scrollable
                                :show-current="true"
                                header-color="disiDarkblue"
                                color="disiIrisblue"
                                :locale=langSelection
                                first-day-of-week="1"
                                width="350"
                                id="datemodalfrom-datepicker"
                            >
                                <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2" @click="datemodalfrom = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2" @click="$refs.datedialogfrom.save(searchDateFrom)">{{ $t('footerButton.save') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <p>{{ $t('general.to') }}:</p>
                        <v-dialog ref="datedialogto" v-model="datemodalto" :return-value.sync="searchDateTo" persistent width="350px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchDateToFormatted"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    class="ml-6 is-modal"
                                    id="datemodalto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="searchDateTo"
                                scrollable
                                :show-current="true"
                                header-color="disiDarkblue"
                                color="disiIrisblue"
                                :locale=langSelection
                                first-day-of-week="1"
                                width="350"
                                id="datemodalto-datepicker"
                            >
                                <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2" @click="datemodalto = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2" @click="$refs.datedialogto.save(searchDateTo)">{{ $t('footerButton.save') }}</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-btn elevation="0" class="content-btn small" @click="getFilteredAuditList()" id="searchbutton"></v-btn>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="auditTableHeaders"
                    :items="audittraillist"
                    id="AuditTable"
                    :headers-length=4
                    :loading="tableLoading"
                    :loader-height="8"
                    :no-data-text=noDataText
                >
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="export" @footerButtonClick="pathmodal = true" :successCheck="showExportSuccess" :progress="showExportProgress"  :class="{'disabled': !checkUserRights('systemAuditExport')}"></footer-button>
            <footer-button buttonFunction="print" @footerButtonClick="print()" :successCheck="showPrintSuccess" :progress=  "showPrintProgress" :class="{'disabled': !checkUserRights('systemAuditPrint')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";
import Textinput from "@/components/TextInput.vue";

export default {
    name: 'AuditTrail',
    components: {
        Textinput,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            originalAuditTrail: {},
            datemodalfrom: false,
            searchDateFrom: '',
            datemodalto: false,
            searchDateTo: '',
            tableLoading: true,
            noDataText: '',
            pathmodal: false,
            exportPath: '',
            showExportProgress: false,
            showExportSuccess: false,
            showPrintProgress: false,
            showPrintSuccess: false,
        }
    },

    computed: {
        ...mapState([
            'systemsettings',
            'auditSearch',
            'audittraillist',
            'pathlist',
        ]),
        langSelection(){
            if (this.systemsettings.language === 'Deutsch'){
                return 'de-DE'
            } else {
                return 'en-GB'
            }
        },
        auditTableHeaders(){
            return [
                { text: this.$t('general.date'), align: 'start', value: 'date', filterable: false, sortable: false},
                { text: this.$t('general.time'), align: 'start', value: 'time', filterable: false, sortable: false},
                { text: this.$t('system.audit.event'), align: 'start', value: 'event', sortable: false},
                { text: this.$t('system.audit.desc'), align: 'start', value: 'desc', sortable: false},
            ]
        },
        searchDateFromFormatted(){
            if (this.searchDateFrom != ""){
                return mixins.methods.userDateFormat(this.searchDateFrom);
            } else {
                return "";
            }
        },
        searchDateToFormatted(){
            if (this.searchDateTo != ""){
                return mixins.methods.userDateFormat(this.searchDateTo);
            } else {
                return "";
            }
        },
    },
    methods: {
        goToSystem(){
            this.$router.push('system');
        },
        getFilteredAuditList(){
            this.tableLoading = true;
            this.$store.dispatch('postAxiosSetter',
                ['/disi/audit/list', {dateFrom: this.searchDateFrom, dateTo: this.searchDateTo, search: this.auditSearch },
                'audittraillist', true]
            ).then(response =>{
                this.tableLoading = false;
                if (response.status === 200) {
                    if (response.data.errorMessage){
                        this.$store.commit('emptyAuditTrailList');
                        this.noDataText = response.data.errorMessage;
                    }
                }
            })
        },
        doExport(){
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/audit/export',
                {pathId: this.exportPath, dateFrom: this.searchDateFrom, dateTo: this.searchDateTo, search: this.auditSearch }])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(()=>{
                            this.showExportSuccess = false;
                        }, 800)
                    }
                })
        },
        print(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/audit/print',
                {dateFrom: this.searchDateFrom, dateTo: this.searchDateTo, search: this.auditSearch }])
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(()=>{
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                })
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],

    created() {
        this.$store.commit('emptyAuditSearch');
        this.searchDateFrom = '';
        this.searchDateTo = '';
        this.getFilteredAuditList();
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
    }
}
</script>
<style scoped lang="scss">

#searchbutton{
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    background-color: $disiIrisblue!important;
    padding: 0;
    position: relative;

    span.v-btn__content{
        display: none;
    }

    &::after{
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        background-image: url("../assets/magnifying-glass_weiss.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: none;
    }
}
</style>